import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import tw from 'twin.macro';
import 'slick-carousel/slick/slick.css';
import { isBrowser, isMobile } from 'react-device-detect';

import { SectionHeading as HeadingTitle } from '../misc/Headings';
import { NextArrow, PreviousArrow } from './components';

import { ReactComponent as SvgDecoratorBlob2 } from 'assets/images/svg-decorator-blob-5.svg';
import { ReactComponent as StarIcon } from 'assets/images/star-icon.svg';

import ToptalLogo from 'assets/images/logos/toptal.avif';
import SmuleLogo from 'assets/images/logos/smule.jpg';
import HoneywellLogo from 'assets/images/logos/honeywell.png';
import DreamixLogo from 'assets/images/logos/dreamix.svg';
import ProgressLogo from 'assets/images/logos/progress.png';
import UniversityOfCambridgeLogo from 'assets/images/logos/university-of-cambridge.png';
import PayhawkLogo from 'assets/images/logos/payhawk.png';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto xs:pt-8 lg:py-20 md:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.h1`mt-2 text-sm md:text-md px-4 md:px-0 text-gray-600 text-center max-w-sm`;
const CardSliderContainer = tw.div`mx-8 md:mx-0 py-4 md:py-0 mt-10 3xl:mt-16 shadow-md sm:shadow-none rounded-xl`;
const Card = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const TextContainer = tw.div`md:border-b-1 md:mx-3 lg:mx-6 md:w-6/12 md:py-4 md:my-2 flex flex-col justify-end`;
const DescriptionContainer = tw.div`relative px-6 pt-2 pb-4 md:p-4 lg:px-10 md:mt-0`;
const Description = tw.blockquote`text-center md:text-left italic text-sm md:text-lg lg:text-lg`;
const CustomerInfo = tw.div`mt-2 md:mt-0 px-5 lg:px-10 text-center md:text-left`;
const CustomerName = tw.span`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500 hover:text-primary-700`;
const CustomerTitle = tw.p`font-medium text-sm text-gray-800`;

const ImageContainer = styled.div`
  ${tw`md:mx-3 md:my-2 lg:mx-6 w-1/2 md:w-1/4 lg:w-1/5 3xl:w-1/4 rounded-xl flex items-center max-w-xs md:shadow-md`}
  img {
    ${tw`rounded-xl`}
  }
`;

const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform xs:translate-x-2/3 lg:translate-x-1/3`;

const RatingsInfo = styled.span`
  ${tw`flex items-center justify-center md:justify-start mt-1`}
  svg {
    ${tw`w-6 h-6 text-yellow-400 fill-current`}
  }
`;

const Rating = tw.span`ml-2 font-bold`;

const CompanyLogoContainer = tw.div`md:my-2  px-5 lg:px-10 flex justify-center md:justify-start`;
const CompanyLogo = styled.img`
  height: ${(props) => (isMobile ? props.mobileHeight || '1.5rem' : props.height || '2rem')};
`;

const settings = {
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  pauseOnHover: false,
  draggable: false,
  autoplaySpeed: 8000,
  arrows: isBrowser,
  dots: false,
};

const TwoColumnImageCarousel = ({
  mentors = [
    {
      id: 0,
      imageSrc:
        'https://mentorsko-internal.gateway.eu1.storjshare.io/production/mentors/63/Miglen.jpg?AWSAccessKeyId=jxphfxgz6uxau3eyvrqlve543p3a&Expires=4869277431&Signature=TngcwujRn8JmFxLAHEwWSw2%2FrQM%3D',
      description: 'Водещ експерт по киберсигурност и основател на Bulgarian CyberSecurity Foundation.',
      customerName: 'Miglen Evlogiev',
      customerTitle: 'Vice President of Information Security',
      companyName: 'Payhawk',
      companyLogoSrc: PayhawkLogo,
    },
    {
      id: 7,
      imageSrc:
        'https://mentorsko-internal.gateway.eu1.storjshare.io/production/mentors/69/cropped-george-milev-karadzhov_original.jpg?AWSAccessKeyId=jxphfxgz6uxau3eyvrqlve543p3a&Expires=4869277431&Signature=gg6akRpHs35AHLEwwvVDcm9ZbOY%3D',
      description:
        'Водещ AI експерт, специализиран в ML и NLP, известен с иновативните си решения в технологичния сектор.',
      customerName: 'Georgi Karadzhov',
      customerTitle: 'ML/NLP Scientist',
      companyName: 'University of Cambridge',
      companyLogoSrc: UniversityOfCambridgeLogo,
      logoSize: 2.2,
    },
    {
      id: 1,
      imageSrc:
        'https://mentorsko-internal.gateway.eu1.storjshare.io/production/mentors/2/stoyan.jpg?AWSAccessKeyId=jxphfxgz6uxau3eyvrqlve543p3a&Expires=4869277426&Signature=8czZ%2BjOusVOd7BduSXURZGjPQOc%3D',
      description: 'Софтуерен архитект и ментор с опит в обучението и подбора на софтуерни инженери.',
      customerName: 'Stoyan Dimkov',
      customerTitle: 'Software Architect',
      companyName: 'Toptal',
      companyLogoSrc: ToptalLogo,
    },
    {
      id: 2,
      imageSrc:
        'https://link.storjshare.io/raw/jxafe7r3ub5mxpk4og5qjcvlafmq/mentorsko-internal/common/ValeriaKalinoProfilePhoto_600_600.jpg',
      description: 'Дизайнер с опит в създаването на бранд и функционални продукти чрез холистичен подход.',
      customerName: 'Valeria Kalinova',
      customerTitle: 'UX/UI Mentor',
      //rating: '5.0',
      companyName: 'Smule',
      companyLogoSrc: SmuleLogo,
      logoSize: 2.5,
    },
    {
      id: 3,
      imageSrc:
        'https://mentorsko-internal.gateway.eu1.storjshare.io/production/mentors/50/StefanGrigorov.jpg?AWSAccessKeyId=jxphfxgz6uxau3eyvrqlve543p3a&Expires=4869277428&Signature=qpaIaYz5ik7CybPoHimJDJTjM88%3D',
      description:
        'Предприемач, съосновател на dreamix.eu и Agile coach с богат опит в намиране на изход в сложни ситуации.',
      customerName: 'Stefan Grigorov',
      customerTitle: 'Entrepreneur / Co-Founder Dreamix',
      companyName: 'Dreamix',
      companyLogoSrc: DreamixLogo,
    },
    {
      id: 5,
      imageSrc:
        'https://mentorsko-internal.gateway.eu1.storjshare.io/production/mentors/23/iliya.jfif?AWSAccessKeyId=jxphfxgz6uxau3eyvrqlve543p3a&Expires=4869277426&Signature=Fsh3Nmkm2ZBj0hSX8ieNbm0e0m0%3D',
      description:
        'Опитен бизнес лидер с фокус върху Business Development, B2B продажби, бизнес стратегии и планиране.',
      customerName: 'Iliya Maksimov',
      customerTitle: 'B2B Sales & Business Development',
      companyName: 'Honeywell',
      companyLogoSrc: HoneywellLogo,
    },
    {
      id: 6,
      imageSrc:
        'https://mentorsko-internal.gateway.eu1.storjshare.io/production/91c6668d-d596-467c-833f-744a21d5b510.jpg?AWSAccessKeyId=jxphfxgz6uxau3eyvrqlve543p3a&Expires=4890554917&Signature=xeobdoHbSdw%2BMz0xL0fNQh4jT4I%3D',
      description: 'Специалист в областта на софтуерната архитектура и разработката на софтуерни приложения.',
      customerName: 'Dimitar Yanis',
      customerTitle: 'Software Engineering Mentor',
      companyName: 'Progress',
      companyLogoSrc: ProgressLogo,
      logoSize: 2.4,
    },
  ],
  heading,
  headingDescription,
}) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  });

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{headingDescription}</HeadingDescription>
        </HeadingInfoContainer>
        <CardSliderContainer>
          <Slider {...settings} ref={sliderRef} nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
            {mentors.length > 0 &&
              mentors.map((card, index) => (
                <Card key={`card-${index}`}>
                  <ImageContainer>
                    <img src={card.imageSrc} alt={card.customerName} />
                  </ImageContainer>
                  <TextContainer>
                    <CustomerInfo>
                      <CustomerName>{card.customerName}</CustomerName>
                      <CustomerTitle>{card.customerTitle}</CustomerTitle>
                      {card.rating && (
                        <RatingsInfo>
                          <StarIcon />
                          <Rating>{card.rating}</Rating>
                        </RatingsInfo>
                      )}
                    </CustomerInfo>
                    <DescriptionContainer>
                      <Description>{card.description}</Description>
                    </DescriptionContainer>
                    {card.companyLogoSrc && (
                      <CompanyLogoContainer>
                        <CompanyLogo
                          src={card.companyLogoSrc}
                          alt={card.companyName}
                          height={card.logoSize ? `${card.logoSize}rem` : undefined}
                          mobileHeight={card.logoSize ? `${card.logoSize - 0.5}rem` : undefined}
                        />
                      </CompanyLogoContainer>
                    )}
                  </TextContainer>
                </Card>
              ))}
          </Slider>
        </CardSliderContainer>
      </Content>
      <DecoratorBlob2 />
    </Container>
  );
};

export default TwoColumnImageCarousel;
