import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import LibHeader, { NavLinks, NavLink, PrimaryLink } from '../headers/Light';
import AuthContext from '../../context/AuthContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { pages } from 'config';
import { USER_KEY, TOKEN_KEY, roles } from 'helpers';

const Header = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { clear, getItem } = useLocalStorage();
  const user = JSON.parse(getItem(USER_KEY));

  const logoutClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    clear(TOKEN_KEY);
    auth.setIsLoggedOptimized(false);
    navigate(pages.login);
  };

  const links = [
    <NavLinks key={1}>
      <NavLink href={pages.portal.mentors.all}>Ментори</NavLink>
      <NavLink href={pages.login} tw='lg:ml-12!'>
        Вход
      </NavLink>
      <PrimaryLink href={pages.becomeMentor}>Стани ментор</PrimaryLink>
    </NavLinks>,
  ];

  const profileLink = useMemo(() => {
    return auth.isLogged
      ? user.roles[0] === roles.mentor
        ? `${pages.portal.mentors.profileLink}/${user.id}`
        : `${pages.portal.user.profileLink}/${user.id}`
      : '';
  }, [auth, user]);

  const loggedInLinks = [
    <NavLinks key={1}>
      <NavLink href={pages.portal.mentors.all}>Ментори</NavLink>
      <NavLink href={profileLink}>Профил</NavLink>
      <PrimaryLink onClick={logoutClickHandler}>Изход</PrimaryLink>
    </NavLinks>,
  ];

  return <LibHeader links={auth.isLogged ? loggedInLinks : links} />;
};

export default Header;
